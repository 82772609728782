import React from "react";
import styles from "./Bonuses.module.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import Icon from "../../imgs/bonuses.svg";

export default function Bonuses() {
  const { t } = useTranslation();

  const contactVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
  };

  const contactVariantsX = {
    initial: { opacity: 0, x: 125 },
    animate: { opacity: 1, x: 0 },
  };
  const contactVariantsMinusX = {
    initial: { opacity: 0, x: -125 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <div className={styles.page}>
      <motion.div
        className={styles.header}
        initial={"initial"}
        whileInView={"animate"}
        transition={{ duration: 0.7 }}
        variants={contactVariants}
      >
        <h1>{t("Trade easily and confidently")}</h1>
        <p>{t("Invest with a Unique AI Trade-Bot!")}</p>
        <br />
        <p>
          {t(
            "Absolutely new technology. Our artificial intelligence software analyzes the market and opens trading positions faster and more efficiently than 88% of global traders."
          )}
        </p>
      </motion.div>

      <div className={styles.container}>
        <div className={styles.info_container}>
          <div className={styles.info}>
            <motion.h1
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.7 }}
              variants={contactVariants}
            >
              {t(
                "Choose the appropriate option and increase your profit without leaving home!"
              )}
            </motion.h1>
            <motion.p
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.7 }}
              variants={contactVariants}
            >
              {t(
                "See for yourself! We offer new clients a 7-day insurance and the opportunity to test passive earnings with our Trade-Bot."
              )}
            </motion.p>
            <motion.span
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.7 }}
              variants={contactVariants}
            >
              {t(
                "Additionally, receive professional risk management consultation from one of the leading financial consultants in the CIS countries."
              )}
            </motion.span>
          </div>
          <div className={styles.blocks}>
            <motion.div
              className={styles.block}
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.9, delay: 0.3 }}
              variants={contactVariantsMinusX}
            >
              <div className={styles.last_block}>
                <span className={styles.title}>
                  <img loading="lazy" src={Icon} alt="icon" className={styles.icon} />{" "}
                  {t("Exchange trading")}
                </span>
                <div className={styles.blocks__background}>
                  <span className={styles.blocks__orange_info}>
                    {t(
                      "You have access to bonuses, personal advisor services and 0% commission."
                    )}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select an asset:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Oil, gas, gold and other raw materials")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Shares and indices of world companies")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="1" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("Cryptocurrency")}</span>
                  </li>
                </ul>
              </div>
              <Link to="/register" className={styles.button}>
                {t("Sign up")}
              </Link>
            </motion.div>
            <motion.div
              className={styles.block}
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.9, delay: 0.3 }}
              variants={contactVariants}
            >
              <div className={styles.last_block}>
                <span className={styles.title}>
                  <img loading="lazy" src={Icon} alt="icon" className={styles.icon} />{" "}
                  {t("Currency exchange")}
                </span>
                <div className={styles.blocks__background}>
                  <span className={styles.blocks__green_info}>
                    {t("We change at the pre-crisis rate!")}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select the amount and find out the rate:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From 30,000 to 80,000 $")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From $81,000 to $200,000")}</span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="2" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>{t("From $200,000 to $1,000,000")}</span>
                  </li>
                </ul>
              </div>
              <Link to="/register" className={styles.button}>
                {t("Sign up")}
              </Link>
            </motion.div>
            <motion.div
              className={styles.block}
              initial={"initial"}
              whileInView={"animate"}
              transition={{ duration: 0.9, delay: 0.3 }}
              variants={contactVariantsX}
            >
              <div className={styles.last_block}>
                <span className={styles.title}>
                  <img loading="lazy" src={Icon} alt="icon" className={styles.icon} />{" "}
                  {t("Investments")}
                </span>
                <div className={styles.blocks__background}>
                  <span className={styles.blocks__blue_info}>
                    {t("Rate 5% per month until December 2024")}
                  </span>
                </div>
                <ul className={styles.list}>
                  {t("Select the appropriate option:")}
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="3" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>
                      {t("With the ability to withdraw interest monthly")}
                    </span>
                  </li>
                  <li>
                    <label className={styles.custom_checkbox}>
                      <input type="radio" name="3" />
                      <span className={styles.checkmark}></span>
                    </label>
                    <span>
                      {t("Without the ability to withdraw interest monthly")}
                    </span>
                  </li>
                </ul>
              </div>
              <Link to="/register" className={styles.button}>
                {t("Sign up")}
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
