import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React from 'react';
import styles from '../styles/Table.module.scss';
import { useTranslation } from 'react-i18next';

export default function Table({ data, columns, header = null, theme }) {
    const { t, i18n } = useTranslation();

    const CustomHeader = () => (
        <div className={styles.customHeader}>
            <span className={styles.leftsideToolbar}></span>
            <span className={styles.rightsideToolbar}></span>
        </div>
    );
    const table = useMaterialReactTable(
        {
            columns,
            data,
            columnResizeMode: 'onChange',
            enableSorting: true,
            enableRowSelection: false,
            enableColumnOrdering: false,
            enableColumnActions: false,
            enablePagination: false, // Отключение пагинации
            getRowId: (row) => row.Id,
            state: {
                showProgressBars: true,
            },
            defaultColumn: {
                maxSize: 1500,
                minSize: 1,
                size: 160,
            },

            //#region table_styles
            displayColumnDefOptions: {
                'mrt-row-select': {
                    size: 30,
                    grow: false,
                },
            },

            muiTableContainerProps: {
                sx:{
                    maxHeight: '21.8vh', // Установка максимальной высоты
                    overflowY: 'auto',
                    "::-webkit-scrollbar": {
                        width: "4px",
                        height: "0px",
                    },
                    "::-webkit-scrollbar-track": {
                        background: theme === 'dark' ? '#1D1D20' : '#FCFCFD',
                    },
                    "::-webkit-scrollbar-thumb": {
                        background: theme === 'dark' ? "#3F3F46" : '#CDD5DF',
                        zIndex: '0'
                    },
                    "::-webkit-scrollbar-thumb:hover": {
                        background: theme === 'dark' ? "#3F3F46" : '#CDD5DF'
                    }, 
                },
            },
            muiTableProps: {
                className: styles.mui_table,
                sx: {
                    caption: {
                        captionSide: 'top',
                        padding: '0px 0px 1.6vh 0px',
                    },
                    borderSpacing: '0 0.8vh',
                    borderCollapse: 'separate',
                },
            },
            muiTableHeadProps: {
                className: styles.mui_table_head,
            },
            muiTableHeadRowProps: {
                className: styles.mui_table_head_row,
                '&.MuiSvgIcon-root': {
                    color: 'red',
                },
            },
            muiTableHeadCellProps: {
                className: styles.table_header_cell,
                sx: {
                    '&.MuiSvgIcon-root': {
                        color: 'red',
                    },
                },
            },
            muiTableBodyProps: {
                className: styles.mui_table_body,
            },
            muiTableBodyRowProps: {
                className: styles.mui_table_body_row,
                sx: {
                    backgroundColor: 'none !important',
                },
            },
            muiTableBodyCellProps: {
                className: styles.table_body_cell,
            },
            localization: {
                noRecordsToDisplay: (
                    <span className={styles.noResultsFound}>{t('NoRecordsResult')}</span>
                ),
                noResultsFound: (
                    <span className={styles.noResultsFound}>{t('NoRecordsResult')}</span>
                ),
            },
            renderBottomToolbar: false,
            renderTopToolbar: false,
            renderCaption: ({ table }) => (header !== null ? header : <CustomHeader />),
        },
        [i18n.language]
    );

    return <MaterialReactTable table={table} />;
}
