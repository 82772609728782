import React from 'react'
import styles from '../styles/Layout.module.scss';
import { Navigate, Outlet } from 'react-router-dom';
import Topbar from './Topbar';
import Navbar from './Navbar';
import secureLocalStorage from 'react-secure-storage';

export default function Layout({theme,toggleTheme,account,setAccount,balance,setBalance,margin,setMargin,eurToUsd,usdToEur,setSnackMessage,setSnackType,handleClick}) {
  if(!secureLocalStorage.getItem('user')){
    return <Navigate to={'/login'}/>
    }else
return (
    <div className={styles.layout}>
        <Topbar toggleTheme={toggleTheme} theme={theme}
              eurToUsd={eurToUsd}
              usdToEur={usdToEur}
              account = {account}
              setAccount = {setAccount}
              balance = {balance}
              setBalance = {setBalance}
              margin = {margin}
              setMargin = {setMargin}
              setSnackMessage={setSnackMessage}
              setSnackType={setSnackType}
              handleClick={handleClick}
              />
        <div className={styles.page}>
            <Navbar account={account}
              setSnackMessage={setSnackMessage}
              setSnackType={setSnackType}
              handleClick={handleClick}
            />
            <Outlet/>
        </div>
    </div>
  )
}
