import React from 'react'
import styles from '../styles/LayoutLanding.module.scss';
import {Outlet } from 'react-router-dom';
import NavBar from './NavbarLanding';
import FooterLanding from './FooterLanding';

export default function LayoutLanding() {

return (
    <div className={styles.layout}>
        <NavBar/>
        <Outlet/>
        <FooterLanding/>
    </div>
  )
}
