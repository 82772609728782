
import React, { useState } from "react"
import styles from './AccountTypes.module.scss'
import { useTranslation } from "react-i18next"
import { motion } from 'framer-motion'
import { Link } from "react-router-dom";

export default function AccountTypes(){
    const {t} = useTranslation();
    const [choosenType , setChoosenType] = useState(1);
    const accountTypes = [
        {
            id: 1,
            name: 'Mini',
            price: '$150 - $3000',
            bonus: '50%',
            withdrawal: t('Standard rules'),
            analytic: true,
            educ: true,
            getToNow: true,
            personalFin: true,
            accountReview: false,
            tradingPlan: '',
            personalStrat: false,
            realtimeTrading:false,
            highPriority: false,
            vipTools: false,
        },
        {
            id: 2,
            name: 'Max',
            price: '$3,001 - $10,000',
            bonus: '100%',
            withdrawal: t('Standard rules'),
            analytic: true,
            educ: true,
            getToNow: true,
            personalFin:true,
            accountReview: t('Twice a month. 15 min'),
            tradingPlan: t('Weekly'),
            personalStrat:true,
            realtimeTrading:false,
            highPriority: false,
            vipTools: false,
        },
        {
            id: 3,
            name: 'Silver',
            price: '$10,001 - $50,000',
            bonus: '120%',
            withdrawal: t('Boosted'),
            analytic: true,
            educ: true,
            getToNow: true,
            personalFin:true,
            accountReview: t('Once a week. 30 min'),
            tradingPlan: t('Monthly'),
            personalStrat:true,
            realtimeTrading:true,
            highPriority: false,
            vipTools: false,
        },
        {
            id: 4,
            name: 'Gold',
            price: '$50,001',
            bonus: '120%',
            withdrawal: t('Highest priority'),
            analytic: true,
            educ: true,
            getToNow: true,
            personalFin:true,
            accountReview: t('Once a week. 2 hours'),
            tradingPlan: t('Quartely'),
            personalStrat:true,
            realtimeTrading:true,
            highPriority: true,
            vipTools: true,
        }
    ];

    const nextPlan = () => {
        setChoosenType((prev) =>
            prev === accountTypes.length ? 1 : prev + 1
        );
    };

    const prevPlan = () => {
        setChoosenType((prev) =>
            prev === 1 ? accountTypes.length : prev - 1
        );
    };

    const contactVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 }
    }
    const choosenPlan = accountTypes.find((el) => el.id === choosenType);

    const successIcon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12ZM16.5303 8.96967C16.8232 9.26256 16.8232 9.73744 16.5303 10.0303L11.5303 15.0303C11.2374 15.3232 10.7626 15.3232 10.4697 15.0303L8.46967 13.0303C8.17678 12.7374 8.17678 12.2626 8.46967 11.9697C8.76256 11.6768 9.23744 11.6768 9.53033 11.9697L11 13.4393L13.2348 11.2045L15.4697 8.96967C15.7626 8.67678 16.2374 8.67678 16.5303 8.96967Z" fill="#00B4A0"/>
    </svg>

    const failIcon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12ZM9.46963 8.96965C9.76252 8.67676 10.2374 8.67676 10.5303 8.96965L12.5 10.9393L14.4696 8.96967C14.7625 8.67678 15.2374 8.67678 15.5303 8.96967C15.8232 9.26256 15.8232 9.73744 15.5303 10.0303L13.5606 12L15.5303 13.9696C15.8232 14.2625 15.8232 14.7374 15.5303 15.0303C15.2374 15.3232 14.7625 15.3232 14.4696 15.0303L12.5 13.0607L10.5303 15.0303C10.2374 15.3232 9.76254 15.3232 9.46965 15.0303C9.17676 14.7374 9.17676 14.2625 9.46965 13.9697L11.4393 12L9.46963 10.0303C9.17673 9.73742 9.17673 9.26254 9.46963 8.96965Z" fill="#E9770E"/>
    </svg>

    const rowsNames = [
        t('Welcome Bonus'),
        t('Withdrawal'),
        t('Analytical materials'),
        t('Educational materials'),
        t('Getting to know the platform'),
        t('Personal financial analyst'),
        t('Individual trading account review with a financial analyst'),
        t('Trading plan'),
        t('Development of a personal strategy'),
        t('Live trading demonstration'),
        t('Highest priority for trading online with a financial analyst'),
        t('VIP trading tools'),
    ]
    return(
        <div className={styles.accountTypes}>
            <motion.div className={styles.header}
                initial={'initial'}
                whileInView={'animate'}
                transition={{ duration: 0.7 }}
                variants={contactVariants} >
                <h1>{t('Account Types')}</h1>
                <p>{t('Preparation first, then action. This is how you make the best trades')}</p>
            </motion.div>
            <motion.div className={styles.accountTypes_block}  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                <div className={styles.typesBody}>
                    <div className={styles.types_header}>
                        <div className={styles.header_name}>
                            <p>{t('Our Features')}</p>
                            <h1>{t('Features')}</h1>
                        </div>
                        {
                            accountTypes.map(el=>
                                <div onClick={()=> setChoosenType(el.id)} key={el.id} className={`${styles.type} ${choosenType == el.id? styles.active : ''}`}>
                                    <p className={styles.name}>
                                        {el.name}
                                    </p>
                                    <div className={styles.center}>
                                        <p>{el.price}</p>
                                        <span>{t('Deposit')}</span>
                                    </div>
                                    <Link to='/register'>
                                        {t("Open")}
                                    </Link>
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.rows}>
                        {
                            Object.keys(accountTypes[0]).slice(3).map((key,index) =>(
                                <div className={styles.types_row}>
                                    <p className={styles.row_name}>{rowsNames[index]}</p>
                                    {accountTypes.map((account, i) => (
                                        <div key={i} className={styles.value}>
                                            {typeof account[key] === 'boolean' ? (
                                                account[key]  ? successIcon : failIcon
                                            ) : (
                                                typeof account[key] === 'string' && account[key] === '' ? failIcon :
                                                <p className={styles.rows_text}>{account[key]}</p>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </motion.div>
            <motion.div className={styles.accountTypes_mobile}  initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.9, delay: 0.3 }}>
                <div className={styles.top}>
                    <div className={styles.name_buttons}>
                        <span className={styles.prev_next} onClick={prevPlan}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10 3.33334L6 8.00001L10 12.6667" stroke="#FCFCFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <p>{choosenPlan.name}</p>
                        <span className={styles.prev_next} onClick={nextPlan}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M6 3.33334L10 8.00001L6 12.6667" stroke="#FCFCFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </div>
                    <div className={styles.center}>
                        <p>{choosenPlan.price}</p>
                        <span>{t("Deposit")}</span>
                    </div>
                    <Link>{t("Open")}</Link>
                </div>
                <div className={styles.rows}>
                    {
                        Object.keys(accountTypes[0]).slice(3).map((key, index) => (
                            <div key={index} className={styles.row}>
                                <p>{rowsNames[index]}</p>
                                <div className={styles.value}>
                                    {typeof choosenPlan[key] === 'boolean' ? (
                                        choosenPlan[key] ? successIcon : failIcon
                                    ) : (
                                        typeof choosenPlan[key] === 'string' && choosenPlan[key] === '' ? failIcon : choosenPlan[key]
                                    )}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </motion.div>
        </div>
    )
}