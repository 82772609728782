import { useState, useRef, useEffect } from 'react';
import styles from '../styles/Carousel.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/scss';

export default function Carousel({slides}) {
    const [activeIndex, setActiveIndex] = useState(0); // Для хранения активного слайда
    const swiperRef = useRef(null); // Создаем реф для Swiper
    const containerRef = useRef(null); // Реф для контейнера слайдера


    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.realIndex % slides.length); 
    };

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideToLoop(index); // Переключаем на нужный слайд с помощью метода slideToLoop
            setActiveIndex(index); // Обновляем активный индекс
        }
    };

    // Обработчик события прокрутки
    const handleScroll = (e) => {
        e.preventDefault();
        const delta = e.deltaY; // Направление скролла

        if (swiperRef.current && swiperRef.current.swiper) {
            if (delta > 0) {
                swiperRef.current.swiper.slideNext(); // Прокрутка вниз - следующий слайд
            } else {
                swiperRef.current.swiper.slidePrev(); // Прокрутка вверх - предыдущий слайд
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleScroll); // Добавляем обработчик события прокрутки
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll); // Удаляем обработчик при размонтировании
            }
        };
    }, []);
    return (
        <div className={styles.carouselWrapper} ref={containerRef}> {/* Привязываем реф к контейнеру */}
            <Swiper
                ref={swiperRef} // Привязываем реф к Swiper
                className={styles.body}
                spaceBetween={9}
                slidesPerView={2.1}
                centeredSlides={true}
                initialSlide={1}
                direction={'vertical'}
                loop={true}
                onSlideChange={handleSlideChange}
                pagination={{ clickable: true }}
                modules={[Pagination]} 
            >
                {slides.map((el) => (
                    <SwiperSlide key={el.id} className={styles.slide}>
                        <div className={styles.slide_content}>
                            <div className={styles.top}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={25} height={22} viewBox="0 0 25 22" fill="none">
                                    <path d="M19.8214 0H25L19.75 11.1273H25V22H14.3214V11.1273L19.8214 0ZM5.46429 0H10.6786L5.39286 11.1273H10.6786V22H0V11.1273L5.46429 0Z" fill="#26272B" />
                                </svg>
                                <p className={styles.text}>{el?.text}</p>
                            </div>
                            <h5 className={styles.name}>{el?.name}</h5>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Кастомная пагинация */}
            <div className={styles.pagination}>
                {slides.map((_, index) => (
                    <span 
                        key={index} 
                        className={`${styles.paginationBullet} ${index === activeIndex ? styles.active : ''}`} 
                        onClick={() => goToSlide(index)} // Используем функцию для переключения слайда
                    />
                ))}
            </div>
        </div>
    );
};
