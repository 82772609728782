import React, { useEffect } from 'react'
import {LinearProgress} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
export default function AutoLogin({setAccounts,setAccount}) {
    const {token,email} = useParams();
    const nav = useNavigate();
    useEffect(()=>{
        secureLocalStorage.clear();
        axios.get(process.env.REACT_APP_test_url + `/api/login/auto/${email}`,
        {
            headers:{
                "Authorization":`Bearer ${token}`
            }
        }).then((res)=>{
                secureLocalStorage.clear();
                secureLocalStorage.setItem("token",res.data.token);
                secureLocalStorage.setItem("user",res.data.user)
                secureLocalStorage.setItem('accounts',res.data.accounts);
                setAccounts(res.data?.accounts);
                if(res.data?.accounts?.length>0){
                    setAccount(res.data?.accounts[0])
                    secureLocalStorage.setItem("selected_account",res.data.accounts[0])
                }
                nav('/cabinet',{replace:true});
            }).catch((err)=>{
                // setError(err.response.data.message);
            })
    },[])

  return (
    <div style={{width:"100%",height:"100vh",backgroundColor:"#000000"}}>
         <LinearProgress sx={{ width: '100%' }} color='inherit' />
    </div>
  )
}
